import React from "react";
import rec1 from "../images/achievable11.png";
import rec2 from "../images/ygaa-one.png";
import rec3 from "../images/ygaa-two.png";
import "../Styles/Components/YourGoalsAchievable.css";
import FancyButtonComp from "./FancyButtonComp";
import fancy from "../images/fancy-arrow.svg";

function YourGoalsAchievable() {
  return (
    <div className="your-goals">
      {/* margin p */}
      <div className="yga-margin">
        {/* left */}
        <div className="yga-left">
          {/* head */}
          <p className="yga-head">Your Goals Are Achievable With Us</p>
          {/* body */}
          <p className="yga-body">
            Foreign Classroom aligns its goals with the goals of our recruitment
            partners by offering students an exciting platform to discover,
            apply and study at universities abroad.
          </p>
          {/* button comp */}
          <FancyButtonComp name={"Sign Up"} />

          {/* curly image */}
          <div className="yga-curly">
            <img src={fancy} alt=""></img>
          </div>
        </div>
        {/* right */}
        <div className="yga-right">
          {/* image comp */}
          <div className="yga-r-comp">
            <img src={rec1} alt=""></img>
          </div>

          {/* image comp */}
          <div className="yga-r-comp">
            <img src={rec2} alt=""></img>
          </div>

          {/* image comp */}
          <div className="yga-r-comp">
            <img src={rec3} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YourGoalsAchievable;
