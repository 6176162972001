import React from "react";
import "../Styles/Pages/Influencers.css";

import influOne from "../images/influnecerSec1.png";
import FancyButtonComp from "./FancyButtonComp";
import arrow from "../images/fancy-arrow.svg";

function InfluencersSec1() {
  return (
    <div className="influ-sec1">
      {/* screen nav */}
      <div></div>

      {/* body */}
      <div className="influ-body">
        {/* margin */}
        <div className="margin-p">
          {/* left */}
          <div className="influ-left">
            <img src={influOne} alt=""></img>
          </div>

          {/* right */}
          <div className="influ-right">
            {/* fancy image */}
            <div className="fancy-img-influ">
              <img src={arrow} alt=""></img>
            </div>

            {/* m body */}
            <div className="influ-body">
              {/* header */}
              <p className="influ-header">Lend Your Voice & Get Value For It</p>

              {/* body */}
              <p className="influs-body">
                Leverage your social media prowress to connect your audience
                with our credible platform that helps students apply and enroll
                n top-ranking international institutions. You also get
                measurable value for it.
              </p>

              {/* button */}
              <FancyButtonComp name={"Become an Influencer"} />
            </div>

            {/* fancy image */}
            <div className="fancy-img-influ-one">
              <img src={arrow} alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfluencersSec1;
