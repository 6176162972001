import React from "react";
import "../Styles/Components/HomePart2.css";
import "../Styles/Components/Footer.css";
import arrow from "../images/arrow-right.svg";

function GetMoreUpdates() {
  return (
    <div className="bluenthings">
      <div className="margin-p2">
        <p className="blue-words">Get More Updates. Join our Newsletter.</p>

        {/* subscribe box */}
        <div className="down-right">
          <div className="input-cover">
            <input type="text" placeholder="Enter your email" />
            {/* button */}
            <div className="input-button">
              <div className="input-btn-img">
                <img src={arrow} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetMoreUpdates;
