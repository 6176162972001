import React from "react";
import "../Styles/Components/HomePart2.css";
import "../Styles/Components/Footer.css";

import GetMoreUpdates from "./GetMoreUpdates";
import PitcureBack from "./PitcureBack";

function HomePart2() {
  return (
    <div className="home-part-two">
      {/* marginp */}
      <div className="">
        {/* blue back */}
        <div className="blue-back"></div>
        {/* picture back */}
        <PitcureBack />
        {/* blue back and things */}

        <GetMoreUpdates />
      </div>
    </div>
  );
}

export default HomePart2;
