import React from "react";

function PitcureBack() {
  return (
    <div className="picture-back">
      {/* layout infront */}
      <div className="black-overlay">
        {/* margin p */}
        <div className="margin-p2">
          {/* sec1 */}
          <div className="hp-sec1">
            <p className="hp-sec1-word">
              <i>Foreign Classroom</i> assists students actualize their dreams
              of studying abroad
            </p>
          </div>
          {/* sec2 */}
          <div className="hp-sec2">
            <p className="hp-sec2-word">“We are committed to your success!”</p>
            <p className="hp-sec2-small">-Mr. Taiwo, CEO</p>
          </div>
          {/* sec3 */}
          <div className="hp-sec3">
            {/* box comp */}
            <div className="hp-box-comp">
              <p className="hpb-numb">3,000</p>
              <p className="hpb-word">APPLCATIONS DONE</p>
            </div>

            {/* box comp */}
            <div className="hp-box-comp">
              <p className="hpb-numb">1,500</p>
              <p className="hpb-word">HAPPY STUDENTS</p>
            </div>

            {/* box comp */}
            <div className="hp-box-comp">
              <p className="hpb-numb">10,000</p>
              <p className="hpb-word">AWARD PROGRAMS</p>
            </div>

            {/* box comp */}
            <div className="hp-box-comp">
              <p className="hpb-numb">12+</p>
              <p className="hpb-word">COUNTRIES WORLDWIDE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PitcureBack;
