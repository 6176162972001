import React from "react";
import AccessToWideSec from "../Components/AccessToWideSec";
import AgentsHero from "../Components/AgentsHero";
import GetMoreUpdates from "../Components/GetMoreUpdates";
import HomePart3 from "../Components/HomePart3";
import HowWeWork from "../Components/HowWeWork";
import JoinGlobalNetwork from "../Components/JoinGlobalNetwork";
import SupportedBy from "../Components/SupportedBy";
import WhyWorkWithForeign from "../Components/WhyWorkWithForeign";
import YourGoalsAchievable from "../Components/YourGoalsAchievable";
import "../Styles/Pages/Agents.css";

function Agents() {
  return (
    <div className="agents">
      <AgentsHero />
      <HowWeWork />
      <SupportedBy />
      <YourGoalsAchievable />
      <WhyWorkWithForeign />
      <AccessToWideSec />
      <JoinGlobalNetwork />
      <div className="agents-gu">
        <GetMoreUpdates />
      </div>

      <HomePart3 />
    </div>
  );
}

export default Agents;
