import React from "react";
import "../Styles/Components/StudentsSec1.css";
import "../Styles/Pages/Students.css";

import GetMoreUpdates from "../Components/GetMoreUpdates";
import HomePart3 from "../Components/HomePart3";
import PageNavCheck from "../Components/PageNavCheck";
import StudentSec2 from "../Components/StudentSec2";
import StudentSec3 from "../Components/StudentSec3";
import StudentsSec1 from "../Components/StudentsSec1";
import SupportedBy from "../Components/SupportedBy";
import back from "../images/student-nav-check2.svg";
function Students() {
  return (
    <div className="students">
      <PageNavCheck title={"Students"} image={back} />
      <StudentsSec1 />
      <StudentSec2 />
      <SupportedBy />
      <StudentSec3 />
      <div className="student-gu">
        <GetMoreUpdates />
      </div>

      {/* <HomePart3 /> */}
    </div>
  );
}

export default Students;
