import React from "react";
import why from "../images/why-aud.png";
import { NavLink } from "react-router-dom";

function WhyAudience() {
  return (
    <div className="why-audience">
      {/* head */}
      <div className="wa-head">
        {/* header */}
        <div className="wah-header">
          <p>WHY YOUR AUDIENCE WILL WANT TO LEARN ABOUT FOREIGN CLASSROOM</p>
        </div>

        {/* circle head */}
        <div className="wah-circle">
          <p>Seamless Application</p>
          <p>Scholarship Hints</p>
          <p>Variety of Options</p>
        </div>
      </div>

      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="why-a-left">
          {/* head */}
          <p className="wal-head">Application</p>

          {/* body */}
          <p className="wal-body">
            The process of applying to study abroad has been made easier with
            Foreign Classroom.{" "}
          </p>

          {/* sign out */}
          <div className="wal-sign-up">
            <NavLink
              to="https://app.foreignclassroom.com/create-application"
              className={"wal-sign-up-nav"}
            >
              <p>Sign Up</p>
            </NavLink>
          </div>
        </div>
        {/* right */}
        <div className="why-a-right">
          <img src={why} alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default WhyAudience;
