import React, { useEffect, useState } from "react";
import "../Styles/Components/HowWeWork.css";
import signImg from "../images/signup.svg";
import hwwOne from "../images/hww-one.svg";
import hwwTwo from "../images/hww-two.svg";
import hwwThree from "../images/hww-three.svg";
import hwwFour from "../images/hww-four.svg";
import HwwComp from "./HwwComp";

function HowWeWork() {
  const [check, setCheck] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      if (check === 1) {
        setCheck(2);
      } else if (check === 2) {
        setCheck(3);
      } else if (check === 3) {
        setCheck(4);
      } else if (check === 4) {
        setCheck(5);
      } else {
        setCheck(1);
      }
    }, 5900);

    return () => clearInterval(interval);
  }, [check]);
  return (
    <div className="how-we-work">
      <p className="hww-header">How We Work</p>

      {/* image horizontal scroll */}
      <div className="margin-p2">
        <div className="image-horizon">
          {/* image frame container */}
          <div className="hww-frame-container">
            {/* images frame 1 */}
            <div className="images-main-h">
              {/* image comp */}
              <HwwComp
                image={signImg}
                num={"1"}
                animation={"one-anime"}
                header={"Sign up as an Agent"}
                word={
                  "Click the sign up buttom and complete your registration as an agent in seconds"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwOne}
                num={"2"}
                animation={"two-anime"}
                header={"Apply for Students"}
                word={
                  "We will communicate ground information to you in order to help guide your tactics within the market region"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwTwo}
                num={"3"}
                animation={"three-anime"}
                header={"Accept Offer"}
                word={
                  "You will get complete and verified applications that meet your requirements"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwThree}
                num={"4"}
                header={"Visa Counselling"}
                word={
                  "Foreign classroom will proffer visa application assistance to students"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwFour}
                num={"5"}
                header={"Commission Payment"}
                word={
                  "Payment of commission will be made once students have been successfully enrolled and have commenced classes"
                }
              />
            </div>{" "}
            {/* images frame 2 */}
            <div className="images-main-h">
              {/* image comp */}
              <HwwComp
                image={signImg}
                num={"1"}
                animation={"one-anime"}
                header={"Sign up as an Agent"}
                word={
                  "Click the sign up buttom and complete your registration as an agent in seconds"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwOne}
                num={"2"}
                animation={"two-anime"}
                header={"Apply for Students"}
                word={
                  "We will communicate ground information to you in order to help guide your tactics within the market region"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwTwo}
                num={"3"}
                animation={"three-anime"}
                header={"Accept Offer"}
                word={
                  "You will get complete and verified applications that meet your requirements"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwThree}
                num={"4"}
                header={"Visa Counselling"}
                word={
                  "Foreign classroom will proffer visa application assistance to students"
                }
              />

              {/* image comp */}
              <HwwComp
                image={hwwFour}
                num={"5"}
                header={"Commission Payment"}
                word={
                  "Payment of commission will be made once students have been successfully enrolled and have commenced classes"
                }
              />
            </div>
          </div>
        </div>

        {/* dots */}
        <div className="dots-group">
          {/* one dot */}
          <div className={check === 1 ? "one-dot-current" : "one-dot"}></div>
          {/* one dot */}
          <div className={check === 2 ? "one-dot-current" : "one-dot"}></div>
          {/* one dot */}
          <div className={check === 3 ? "one-dot-current" : "one-dot"}></div>
          {/* one dot */}
          <div className={check === 4 ? "one-dot-current" : "one-dot"}></div>
          {/* one dot */}
          <div className={check === 5 ? "one-dot-current" : "one-dot"}></div>
        </div>
      </div>
    </div>
  );
}

export default HowWeWork;
