import React, { useEffect } from "react";
import Logo from "./Logo";
import ham from "../images/hamburger.svg";
import cancel from "../images/cancel.svg";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/logo-jpg.jpg";
import { useState } from "react";

function PhoneHeader() {
  const [display, setDisplay] = useState(false);
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  return (
    <div className="phone-header">
      {/* main phone div */}
      <div className="main-phone-header">
        {/* margin */}
        <NavLink
          to="/"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          <div className="phone-logo">
            <img src={logo} alt="" />
          </div>
        </NavLink>

        {/* hamburger menu */}
        {display ? (
          <div
            className="hamburger"
            onClick={() => {
              setDisplay(false);
              console.log("clicked");
            }}
          >
            <img src={cancel} alt=""></img>
          </div>
        ) : (
          <div
            className="hamburger"
            onClick={() => {
              setDisplay(true);
            }}
          >
            <img src={ham} alt=""></img>
          </div>
        )}
      </div>

      {/* hidden navigation */}
      <div className={display ? "hidden-nav" : "display-none"}>
        {/* nav comp */}
        <NavLink
          to="/"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => <p className={isActive && "nav-current"}>Home</p>}
        </NavLink>
        {/* nav */}
        <NavLink
          to="/students"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => (
            <p className={isActive && "nav-current"}>Students</p>
          )}
        </NavLink>
        {/* nav */}
        <NavLink
          to="/agents"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => (
            <p className={isActive && "nav-current"}>Agents</p>
          )}
        </NavLink>
        {/* nav */}
        <NavLink
          to="/influencers"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => (
            <p className={isActive && "nav-current"}>Influencers</p>
          )}
        </NavLink>
        {/* nav */}
        <NavLink
          to="/partnered-schools"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => (
            <p className={isActive && "nav-current"}>Partnered Schools</p>
          )}
        </NavLink>
        {/* nav */}
        <NavLink
          to="/about-us"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => (
            <p className={isActive && "nav-current"}>About Us</p>
          )}
        </NavLink>
        {/* nav */}
        <NavLink
          to="/contact-us"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
            moveToTop();
          }}
        >
          {({ isActive }) => (
            <p className={isActive && "nav-current"}>Contact Us</p>
          )}
        </NavLink>

        <NavLink
          to="/login"
          className="h-nav-comp"
          onClick={() => {
            setDisplay(false);
          }}
        >
          {({ isActive }) => <p className={isActive && "nav-current"}>login</p>}
        </NavLink>
      </div>
    </div>
  );
}

export default PhoneHeader;
