import React from "react";

function SchoolComponent({ imageOne, imgOne }) {
  return (
    <div className="list-school-component">
      {/* top */}
      <div className="lsc-top">
        <img src={imageOne} alt=""></img>
      </div>

      {/* bottom */}
      <div className="lsc-bottom">
        {/* top */}
        <p className="lsc-b-p">
          Pre-service Firefighter Education and Training (FIRE)
        </p>
        {/* bottom */}
        <div className="lsc-b-bottom">
          {/* left */}
          <p className="lsc-bb-p">Georgian College Canada</p>
          {/* right */}
          <div className="lsc-bb-logo">
            <img src={imgOne} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolComponent;
