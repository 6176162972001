import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "../Pages/AboutUs";
import Agents from "../Pages/Agents";
import ContactUs from "../Pages/ContactUs";
import Home from "../Pages/Home";
import Influencers from "../Pages/Influencers";
import PartneredSchools from "../Pages/PartneredSchools";
import Students from "../Pages/Students";
import Footer from "./Footer";
import Header from "./Header";
import PhoneHeader from "./PhoneHeader";
import "../Styles/Components/MainContainer.css";
import ListOfSchools from "../Pages/ListOfSchools";
// import { Freshchat } from "reactjs-freshchat";
// import "reactjs-freshchat/dist/index.css";

function MainContainer() {
  useEffect(() => {
    function initFreshChat() {
      window?.fcWidget?.init({
        token: "ddb7faaf-7e94-4bb6-959e-ca8afaab17df",
        host: "https://wchat.freshchat.com",
      });
    }

    function initialize(i, t) {
      var e;
      if (i.getElementById(t)) {
        initFreshChat();
      } else {
        e = i.createElement("script");
        e.id = t;
        e.async = true;
        e.src = "https://wchat.freshchat.com/js/widget.js";
        e.onload = initFreshChat;
        i.head.appendChild(e);
      }
    }

    function initiateCall() {
      initialize(document, "Freshchat-js-sdk");
    }

    if (window.addEventListener) {
      window.addEventListener("load", initiateCall, false);
    } else {
      window.attachEvent("load", initiateCall, false);
    }
  }, []);

  function openFreshchat() {
    // Open Freshchat widget
    window.fcWidget?.open();
    console.log("working");
  }

  return (
    <div id="Freshchat-js-sdk">
      <Header />
      <PhoneHeader />
      <div className="m-container-main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/students" element={<Students />} />
          <Route path="/agents" element={<Agents />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/influencers" element={<Influencers />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/partnered-schools" element={<PartneredSchools />} />
          <Route path="/list-of-schools" element={<ListOfSchools />} />
        </Routes>
        {/* <Freshchat token={"ddb7faaf-7e94-4bb6-959e-ca8afaab17df"} /> */}
        {/* fresh chat container */}
        {/* <div className="fresh-cont"></div> */}
        {/* <button
          onClick={() => {
            openFreshchat();
          }}
        >
          Open Freshchat
        </button> */}
        <Footer />
      </div>
    </div>
  );
}

export default MainContainer;
