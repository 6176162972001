import React from "react";
// styles
import "../Styles/Components/Header.css";
// Images
import logo from "../images/logo.svg";
import Logo from "./Logo";
import { Link, NavLink } from "react-router-dom";
function Header() {
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="header">
      <div className="margin-head">
        <NavLink
          to="/"
          onClick={() => {
            moveToTop();
          }}
        >
          <Logo />
        </NavLink>

        {/* navigation */}
        <div className="navigation">
          {/* nav */}
          <NavLink
            to="/"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>Home</p>
            )}
          </NavLink>
          {/* nav */}
          <NavLink
            to="/students"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>
                Students
              </p>
            )}
          </NavLink>
          {/* nav */}
          <NavLink
            to="/agents"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>Agents</p>
            )}
          </NavLink>
          {/* nav */}
          <NavLink
            to="/influencers"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>
                Influencers
              </p>
            )}
          </NavLink>
          {/* nav */}
          <NavLink
            to="/partnered-schools"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>
                Partnered Schools
              </p>
            )}
          </NavLink>
          {/* nav */}
          <NavLink
            to="/about-us"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>
                About Us
              </p>
            )}
          </NavLink>
          {/* nav */}
          <NavLink
            to="/contact-us"
            className="nav"
            onClick={() => {
              moveToTop();
            }}
          >
            {({ isActive }) => (
              <p className={isActive ? "nav-current" : "nav-stroke"}>
                Contact Us
              </p>
            )}
          </NavLink>
        </div>

        {/* login button */}
        <div className="login-btn">
          <NavLink
            to="https://app.foreignclassroom.com/create-application"
            className="nav"
          >
            {({ isActive }) => <button>Login</button>}
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Header;
