import React from "react";

function AccessComp({ image, header, word }) {
  return (
    <div className="access-comp">
      {/* left */}
      <div className="access-left">
        <img src={image} alt=""></img>
      </div>
      {/* right */}
      <div className="access-right">
        {/* head */}
        <p className="access-head">{header}</p>
        {/* body */}
        <p className="access-body">{word}</p>
      </div>
    </div>
  );
}

export default AccessComp;
