import React from "react";
import "../Styles/Pages/Login.css";

function LoginPart() {
  return (
    <form>
      {/* head */}
      <div className="login-head">
        <p>Login</p>
      </div>

      {/* email input */}
      <div className="login-input">
        <label>Email</label>
        <input type="email"></input>
      </div>

      {/* password input */}
      <div className="login-input">
        <label>Password</label>
        <input type="password"></input>
      </div>

      {/* remember me */}
      <div className="remember">
        <input type="checkbox"></input>
        <label>Remember me</label>
      </div>

      {/* submit button */}
      <div className="login-btn2">
        <p>Login</p>
      </div>
    </form>
  );
}

export default LoginPart;
