import React from "react";
import "../Styles/Components/Footer.css";
import Logo from "./Logo";
import twitter from "../images/twitter.svg";
import instagram from "../images/instagram.svg";
import linkedin from "../images/linkedin.svg";
import facebook from "../images/facebook.svg";
import pinterest from "../images/pinterest.svg";
import youtube from "../images/youtube.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import phone from "../images/phone.svg";
import arrow from "../images/arrow-right.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-margin">
        {/* left side *********************/}
        <div className="left-side">
          <div className="left-back">
            {/* logo */}
            <div className="foot-logo">
              <Logo />
            </div>

            {/* paragraph */}
            <div className="paragraph">
              <p>
                Foreign Classroom is a web based platform, created with the
                motive to transform the global educational service sector
                through innovative technology. We take pride in assisting
                students actualize their ambitions to study abroad
              </p>
            </div>

            {/* follow  */}
            <div className="follow">
              <p className="p-follow">Follow us</p>
              <div className="follow-back">
                {/* follow comp */}
                <div className="follow-circle">
                  <img src={twitter} alt=""></img>
                </div>
                {/* follow comp */}
                <div className="follow-circle">
                  <img src={instagram} alt=""></img>
                </div>
                {/* follow comp */}
                <div className="follow-circle">
                  <img src={facebook} alt=""></img>
                </div>

                {/* follow comp */}
                <div className="follow-circle">
                  <img src={linkedin} alt=""></img>
                </div>
                {/* follow comp */}
                <div className="follow-circle">
                  <img src={pinterest} alt=""></img>
                </div>
                {/* follow comp */}
                <div className="follow-circle">
                  <img src={youtube} alt=""></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right side *******************/}
        <div className="right-side">
          {/* upside */}
          <div className="foot-upside">
            {/* first part */}
            <div className="foot-up-first">
              {/* flex headers */}
              <div className="flex-headers">
                <p className="head-flex">About</p>
                <div className="navv-div">
                  <p className="navv-foot">our story</p>
                </div>
                <div className="navv-div">
                  <p className="navv-foot">Careers</p>
                </div>
                <div className="navv-div">
                  <p className="navv-foot">Blog</p>
                </div>
                <div className="navv-div">
                  <p className="navv-foot">Press</p>
                </div>
                <div className="navv-div">
                  <p className="navv-foot">Life</p>
                </div>
                <div className="navv-div">
                  <p className="navv-foot">Leadership</p>
                </div>
                <div className="navv-div">
                  <p className="navv-foot">Contact</p>
                </div>
              </div>
              {/* flex headers */}
              <div className="flex-headers">
                {/* up-part */}
                <div className="up-part">
                  <p className="head-flex">Discover</p>
                  <div className="navv-div">
                    <p className="navv-foot">Discover Programs</p>
                  </div>
                  <div className="navv-div">
                    <p className="navv-foot">Discover schools</p>
                  </div>
                </div>
                {/* down part */}
                <div className="down-part">
                  <p className="head-flex">Legal</p>
                  <div className="navv-div">
                    <p className="navv-foot">Privacy & Cookies Policy</p>
                  </div>
                  <div className="navv-div">
                    <p className="navv-foot">Terms & Conditions</p>
                  </div>
                </div>
              </div>

              {/* flex headers*/}
              <div className="flex-headers fh-first">
                <p className="head-flex">Get in Touch</p>
                <div className="navv-div">
                  <img src={phone}></img>
                  <p className="navv-foot">(1) 803 1234 5677</p>
                </div>
                <div className="navv-div">
                  <img src={mail}></img>
                  <p className="navv-foot">info@foreignclassroom.com</p>
                </div>
                <div className="navv-div">
                  <img src={location}></img>
                  <p className="navv-foot">
                    10, Esomo close, Ikeja Lagos, Nigeria.
                  </p>
                </div>
              </div>
            </div>

            {/* second part */}
            <div className="foot-up-second">
              <p className="head-flex">Get in Touch</p>
              <div className="navv-div">
                <img src={phone}></img>
                <p className="navv-foot">(1) 803 1234 5677</p>
              </div>
              <div className="navv-div">
                <img src={mail}></img>
                <p className="navv-foot">info@foreignclassroom.com</p>
              </div>
              <div className="navv-div">
                <img src={location}></img>
                <p className="navv-foot">
                  10, Esomo close, Ikeja Lagos, Nigeria.
                </p>
              </div>
            </div>
          </div>

          {/* downside */}
          <div className="downside">
            <p>Copyright by Foreign Classroom. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
