import React from "react";
import enroll from "../images/enrollment.svg";
import know from "../images/knowledge.svg";
import quality from "../images/quality.svg";
import enrich from "../images/enrich.svg";
import extend from "../images/extended.svg";
import PartnerComp from "./PartnerComp";
import FancyButtonComp from "./FancyButtonComp";

function PartnerWithForeign() {
  return (
    <div className="partner-w-foreign">
      {/* header */}

      {/* margin */}
      <div className="margin-p2">
        {" "}
        <p className="pwf-header">Why Partner with Foreign Classroom?</p>
        {/* flex */}
        <div className="pwf-flex">
          {/* comp */}
          <PartnerComp
            image={enroll}
            head={"Increased Enrollment"}
            body={" We help you reach a wide student population and market"}
            color={"EDFF20"}
          />
          <PartnerComp
            image={know}
            head={"Market Knowledge"}
            body={
              "  We will communicate ground information to you in order to help guide your tactics within the market region"
            }
            color={"F8DBD1"}
          />
          <PartnerComp
            image={quality}
            head={"Quality Application"}
            body={
              "You will get complete and verified applications that meet your  requirements"
            }
            color={"F5B5FF"}
          />
          <PartnerComp
            image={enrich}
            head={"Enrich Your Campus"}
            body={
              " With our extensive network of learners around the globe, your campus will be enriched with diverse cultural backgrounds"
            }
            color={"c36E973"}
          />
          <PartnerComp
            image={extend}
            head={"Extended Recruitment Team"}
            body={
              "  Maximize our acquittance with prominent local agents as this is36 advantageous to your campus visibility and recruitment"
            }
            color={"c2CE4F0"}
          />
        </div>
        <div className="pwf-button">
          <FancyButtonComp name="Work With Us" />
        </div>
      </div>
    </div>
  );
}

export default PartnerWithForeign;
