import React, { useEffect, useState } from "react";
import ImageSlider, { Slide } from "react-auto-image-slider";
// 1st set of images
import img1 from "../images/hero-img11.svg";
import img2 from "../images/hero-img22.svg";
import img3 from "../images/hero-img33.svg";
import img4 from "../images/hero-img44.svg";

import img5 from "../images/blog-11.svg";
import img6 from "../images/blog-22.svg";
import img7 from "../images/what-are-clients.png";
import img8 from "../images/why-foreign.png";

import HeroComp from "./HeroComp";

function HomeHero() {
  const [check, setCheck] = useState(1);
  const imageArrayOne = [img1, img2, img3, img4];
  const imageArrayTwo = [img5, img6, img7, img8];
  const [newArray, setNewArray] = useState(imageArrayOne);

  // create a function with a timer to return the 4 elements until the count is greater
  // than the length of the array

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (check === 1) {
  //       setNewArray(imageArrayOne);
  //       setCheck(2);
  //     } else {
  //       setNewArray(imageArrayTwo);
  //       setCheck(1);
  //     }

  //     console.log("moved 10");
  //     console.log("array", newArray);
  //     console.log("array[0]", newArray[0]);
  //   }, 60000);

  //   return () => clearInterval(interval);
  // }, [check]);

  return (
    <div className="home-hero">
      <ImageSlider effectDelay={1000} autoPlayDelay={5000}>
        <Slide>
          <HeroComp
            image={newArray[0]}
            word={"Admission Hub For International Students!"}
            button={"Apply Now"}
          />
        </Slide>
        <Slide>
          <HeroComp
            image={newArray[1]}
            word={"Easy Program Search"}
            button={"Get Started"}
          />
        </Slide>
        <Slide>
          <HeroComp
            image={newArray[2]}
            word={"Partner with Us"}
            button={"Partner with Us"}
          />
        </Slide>
        <Slide>
          <HeroComp
            image={newArray[3]}
            word={"Engage Your Audience and Get Paid"}
            button={"Work With Us"}
          />
        </Slide>
      </ImageSlider>
    </div>
  );
}

export default HomeHero;
