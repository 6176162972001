import React, { useRef, useState } from "react";
import "../Styles/Components/HomeSec2.css";
import "../Styles/Components/HomeSec1.css";
import clicker from "../images/onclick.svg";
import pic from "../images/hs2-pic.svg";
import FancyButtonComp from "./FancyButtonComp";

function HomeSec2({ head, body, image, button, small }) {
  const section = useRef();
  const [change, setChange] = useState(false);

  window.addEventListener("scroll", () => {
    var top = section.current?.getBoundingClientRect().top;

    if (top < window.innerHeight - 200) {
      setChange(true);
    }
  });
  return (
    <div className="home-sec-two" ref={section}>
      {/* margin p */}
      <div className="margin-p2">
        <div className="home-sec-two-flex">
          {/* left */}

          <div className={small ? "hs2-left1" : "hs2-left"}>
            {/* image */}
            <div className={change ? "hs2-left-img" : "display-slide"}>
              <img src={image} alt=""></img>
            </div>
          </div>

          {/* right */}
          <div className="hs2-right">
            {/* head */}
            <p className="hs1-head">{head}</p> {/* body */}
            <p className="hs1-body">{body}</p>
            {/* button */}
            <FancyButtonComp name={button} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSec2;
