import React from "react";
import AboutSec1 from "../Components/AboutSec1";
import ASeamless from "../Components/ASeamless";
import GetMoreUpdates from "../Components/GetMoreUpdates";
import HomePart3 from "../Components/HomePart3";
import PageNavCheck from "../Components/PageNavCheck";
import PitcureBack from "../Components/PitcureBack";
import SupportedBy from "../Components/SupportedBy";
import WeAreDedicated from "../Components/WeAreDedicated";
import WhatAreClientsSaying from "../Components/WhatAreClientsSaying";
import back from "../images/agent-back.png";

function AboutUs() {
  return (
    <div className="about-us">
      <PageNavCheck title={"About Us"} image={back} />
      {/* sec1 */}
      <AboutSec1 />
      <SupportedBy />
      {/* sec2 */}
      <ASeamless />
      {/* sec3 */}

      <WeAreDedicated />
      {/* picture back */}
      <PitcureBack />
      {/* what are clients */}
      <WhatAreClientsSaying />
      <div className="about-gu">
        <GetMoreUpdates />
      </div>
      {/* <HomePart3 /> */}
    </div>
  );
}

export default AboutUs;
