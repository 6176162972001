import React, { useState, useEffect } from "react";

function HwwComp({ animation, image, num, header, word }) {
  const [state, setState] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (state) {
        setState(false);
      } else {
        setState(true);
      }
    }, 3000);

    console.log(state);

    return () => clearInterval(interval);
  }, [state]);
  const animeStyles = {
    animationPlayState: { state },
  };
  return (
    <div className={`image-b-comp`}>
      {/* top back */}
      <div className="top-back"></div>

      <div className="image-word-main">
        {/* icon */}
        <div className="image-h-icon">
          <img src={image} alt=""></img>
        </div>
        {/* step */}
        <p className="image-h-step">Step {num}</p>
        {/* head */}
        <p className="image-h-head">{header}</p>
        {/* word */}
        <p className="image-h-word">{word}</p>
      </div>
    </div>
  );
}

export default HwwComp;
