import React, { useRef, useState } from "react";
import "../Styles/Components/HomeSec1.css";
import clicker from "../images/onclick.svg";
import fancy from "../images/fancy-arrow.svg";
import blue from "../images/circle-blue.svg";
import red from "../images/circle-red.svg";
import pic1 from "../images/hs1-pic1.svg";
import pic2 from "../images/hs1-pic2.svg";
import FancyButtonComp from "./FancyButtonComp";

function HomeSec1() {
  return (
    <div className="home-sec-one">
      {/* margin */}
      <div className="margin-p">
        {/* left */}
        <div className="hs1-left">
          <div className="hs1-left-img">
            <img src={fancy} alt=""></img>
          </div>

          <div className="hs1-left-div">
            {/* head */}

            <p className="hs1-head">
              Admission Hub for International Students!
            </p>

            {/* body */}
            <p className="hs1-body">
              Foreign classroom takes pride in assisting students actualize
              their ambitions to study abroad
            </p>
            {/* button */}
            <FancyButtonComp name={"Apply Now"} />
          </div>
        </div>

        {/* right */}
        <div className="hs1-right">
          {/* up */}
          <div className="hs1-up">
            {/* left */}
            <div className={"hs1-up-left"}>
              <img src={pic1} alt=""></img>
            </div>
            {/* right */}
            <div className={"hs1-up-right"}>
              <img src={red} alt=""></img>
            </div>
          </div>
          {/* down */}
          <div className="hs1-down">
            {/* left */}
            <div className={"hs1-down-left"}>
              <img src={blue} alt=""></img>
            </div>
            {/* right */}
            <div className={"hs1-down-right"}>
              <img src={pic2} alt=""></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSec1;
