import React from "react";
import SchoolComponent from "../Components/SchoolComponent";
import imageOne from "../images/list-of-schools1.svg";
import imgOne from "../images/loschools1.svg";
import "../Styles/Pages/ListOfSchools.css";

function ListOfSchools() {
  return (
    <div className="list-of-schools">
      {/* margin */}
      <div className="margin-p2">
        <div className="list-margin">
          {/* left */}
          <div className="list-left">
            {/* white background */}
            <div className="list-left-white"></div>
          </div>

          {/* right */}
          <div className="list-right">
            {/* school component */}
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
            <SchoolComponent imageOne={imageOne} imgOne={imgOne} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListOfSchools;
