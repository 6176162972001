import React from "react";
import "../Styles/Components/StudentsSec1.css";
import svg from "../images/student-svg.svg";
import svgOne from "../images/student-svg1.svg";
import svgTwo from "../images/student-svg2.svg";
import svgThree from "../images/student-svg3.svg";
import svgFour from "../images/student-svg4.svg";
import StepComponent from "./StepComponent";

function StudentSec2() {
  return (
    <div className="student-sec2">
      {/* scroll */}
      <div className="margin-p2">
        <div className="ss2-scroll">
          {/* student frame container */}
          <div className="stu-frame-container">
            {/* flex frame 1 */}
            <div className="ss2-flex">
              {/* main component */}
              <StepComponent
                image={svg}
                number={"1"}
                word={"Program & University Search"}
              />

              <StepComponent
                image={svgOne}
                number={"2"}
                word={"Apply to Schools"}
              />

              <StepComponent
                image={svgTwo}
                number={"3"}
                word={"Accept Offers"}
              />
              <StepComponent
                image={svgThree}
                number={"4"}
                word={"Visa Application & Travel Advice"}
              />
              <StepComponent
                image={svgFour}
                number={"5"}
                word={"Round-The-Clock Support"}
              />
            </div>

            {/* flex frame 2 */}
            <div className="ss2-flex">
              {/* main component */}
              <StepComponent
                image={svg}
                number={"1"}
                word={"Program & University Search"}
              />

              <StepComponent
                image={svgOne}
                number={"2"}
                word={"Apply to Schools"}
              />

              <StepComponent
                image={svgTwo}
                number={"3"}
                word={"Accept Offers"}
              />
              <StepComponent
                image={svgThree}
                number={"4"}
                word={"Visa Application & Travel Advice"}
              />
              <StepComponent
                image={svgFour}
                number={"5"}
                word={"Round-The-Clock Support"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentSec2;
