import React from "react";
import LoginOther from "../Components/LoginOther";
import LoginPart from "../Components/LoginPart";
import Logo from "../Components/Logo";
import "../Styles/Pages/Login.css";
import login from "../images/login-img.png";
import { Link, NavLink } from "react-router-dom";

function Login() {
  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };
  return (
    <div className="login">
      {/* left */}
      <div className="login-left">
        {/* logo */}
        <div className="login-logo">
          <NavLink
            to="/"
            onClick={() => {
              moveToTop();
            }}
          >
            <Logo />
          </NavLink>
        </div>

        {/* login part */}

        <LoginPart />

        {/* or line */}
        <div className="or-line">
          <div className="line"></div>
          <div className="or-p">OR</div>
          <div className="line"></div>
        </div>

        {/* login with other things */}

        <LoginOther />
      </div>

      {/* right */}
      <div className="login-right">
        <img src={login} alt=""></img>
      </div>
    </div>
  );
}

export default Login;
