import React from "react";

function BlogComp({ head, date, body, image }) {
  return (
    <>
      <div className="hp3r-blog-comp">
        {/* left */}
        <div className="hp3r-b-left">
          <img src={image} alt=""></img>
        </div>
        {/* right */}
        <div className="hp3r-b-right">
          {/* head */}
          <p className="hbr-head">{head}</p>

          {/* body */}
          <p className="hbr-body">
            {body}
            <i className="hbr-read"> Read More</i>
          </p>

          {/* date */}
          <p className="hbr-date">{date}</p>
        </div>
      </div>
      {/* blue bar */}
      <div className="hbr-blue-bar"></div>
    </>
  );
}

export default BlogComp;
