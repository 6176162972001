import React, { useRef, useState } from "react";
import "../Styles/Components/HomeSec2.css";
import "../Styles/Components/HomeSec1.css";
import clicker from "../images/onclick.svg";
import pic from "../images/hs3-pic.svg";
import FancyButtonComp from "./FancyButtonComp";

function HomeSec3() {
  const section = useRef();
  const [change, setChange] = useState(false);

  window.addEventListener("scroll", () => {
    var top = section.current?.getBoundingClientRect().top;

    if (top < window.innerHeight - 150) {
      setChange(true);
    }
  });
  return (
    <div className="home-sec-two" ref={section}>
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="hs2-right">
          {/* head */}
          <p className="hs1-head">Partner with Us</p>
          {/* body */}
          <p className="hs1-body">
            Offer your students the opportunity to study at leading educational
            institutions across the globe
          </p>
          {/* button */}
          <FancyButtonComp name={"Partner With Us"} />
        </div>
        {/* right */}
        <div className="hs2-left">
          {/* image */}
          <div className={change ? "hs2-left-img2" : "display-slide"}>
            <img src={pic} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSec3;
