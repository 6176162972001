import React from "react";
import FancyButtonComp from "../Components/FancyButtonComp";
import "../Styles/Pages/ContactUs.css";
import img from "../images/contact-img1.png";
import fancy from "../images/fancy-arrow.svg";
import PageNavCheck from "../Components/PageNavCheck";
import back from "../images/contact-back.svg";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import logo from "../images/logo.svg";

function ContactUs() {
  return (
    <div className="contact-us">
      {/* contact nav */}
      <PageNavCheck title={"Contact Us"} image={back} />
      <div className="margin-p2">
        {/* big image */}
        <div className="contact-img">
          <img src={img} alt=""></img>
        </div>

        {/* head */}
        <div className="contact-head">
          {/* header */}
          <p className="contact-header">Talk to an Expert</p>
          {/* body */}
          <p className="contact-body">
            We promise your messages won’t disappear into the abyss. We are
            standing by and ready to help with any questions, comments or
            thoughts you may have.
          </p>
        </div>

        {/* main */}
        <div className="contact-main">
          {/* left */}
          <div className="contact-left">
            <div className="lets-chat">
              <p className="lets-chat-p">Let's Chat</p>
              <div className="lets-chat-img">
                <img src={fancy} alt=""></img>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="contact-right">
            <form className="contact-form">
              {/* name */}
              <div className="cf-name">
                {/* first */}
                <div className="login-input">
                  <label>First name</label>
                  <input type="text"></input>
                </div>

                {/* last */}
                <div className="login-input">
                  <label>Last name</label>
                  <input type="text"></input>
                </div>
              </div>

              {/* email */}
              <div className="login-input">
                <label>Email</label>
                <input type="email"></input>
              </div>

              {/* comments */}
              <div className="contact-text-area">
                <label>Comment or Message</label>
                <textarea></textarea>
              </div>

              {/* button */}
              <FancyButtonComp name={"Submit"} />

              {/* whatsapp */}
              <WhatsAppWidget
                phoneNo="+234 703 724 8318"
                position="right"
                iconSize="60"
                iconColor="white"
                iconBgColor="#25d366"
                headerIcon={logo}
                headerIconColor="pink"
                headerTxtColor="black"
                headerBgColor="rgba(202, 238, 252, 0.99)"
                headerTitle="Foreignclassroom"
                headerCaption="(Online)"
                bodyBgColor="#093c80"
                chatPersonName="Support"
                chatMessage={
                  <>
                    <br />
                    <p>Hi there 👋 </p>
                    <p>How can I help you?</p>
                  </>
                }
                footerBgColor="#ebebeb"
                btnBgColor="#ef3d5b"
                btnTxtColor="white"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
