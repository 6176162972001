import React from "react";

function WeAreComp({ Png, Svg, head, word }) {
  return (
    <div className="wed-flex">
      {/* left */}
      <div className="wedf-left">
        <div className="wl-white-back">
          {/* image */}
          <div className="wl-images">
            {/* 1st */}
            <div className="wl-first">
              <img src={Png} alt=""></img>
            </div>

            {/* 2nd */}
            <div className="wl-second">
              <img src={Svg} alt=""></img>
            </div>
          </div>

          {/* word */}
          <p className="wl-word">{head}</p>
        </div>
      </div>

      {/* right */}
      <div className="wedf-right">
        <p className="wedfr-p">{word}</p>
      </div>
    </div>
  );
}

export default WeAreComp;
