import React from "react";
import HomeHero from "../Components/HomeHero";
import HomePart2 from "../Components/HomePart2";
import HomePart3 from "../Components/HomePart3";
import HomeSec1 from "../Components/HomeSec1";
import HomeSec2 from "../Components/HomeSec2";
import HomeSec3 from "../Components/HomeSec3";
import SupportedBy from "../Components/SupportedBy";
import pic from "../images/hs2-pic.svg";
import pic1 from "../images/hs4-pic.svg";
import "../Styles/Pages/Home.css";

function Home() {
  return (
    <div className="home-styles">
      {/* home hero */}
      <HomeHero />
      {/* supported by */}
      <SupportedBy />
      {/* section1 */}
      <HomeSec1 />

      {/* section 2 */}
      <HomeSec2
        head={"Easy Program Search!"}
        body={
          "Enjoy access to multitude of school and program options that best fit your profile and interest"
        }
        image={pic}
        button={"Get Started"}
      />

      {/* section 3 */}
      <HomeSec3 />

      {/* section 4 */}
      <HomeSec2
        head={"Engage Your Audience and Get Paid"}
        body={
          "Do you have loyal followership on any social media platform? Engage your audience with Foreign Classroom to study  abroad and get paid"
        }
        image={pic1}
        button={"Work With Us"}
        small={true}
      />

      {/* home part two */}
      <HomePart2 />

      {/* home part three */}
      {/* <HomePart3 /> */}
    </div>
  );
}

export default Home;
