import React from "react";
import canada from "../images/canada.png";
import usa from "../images/usa.png";
import aus from "../images/Australia.png";
import canada2 from "../images/canada2.png";

function Partners() {
  return (
    <div className="partner">
      {/* header */}
      <p className="partner-header">Partnered Schools</p>
      {/* margin */}
      <div className="margin-p2">
        {/* flex area */}
        <div className="partner-flex">
          {/* comp 1 */}
          <div className="partner-comp">
            <img src={canada} alt=""></img>
            {/* hidden area */}
            <div className="partner-hide">
              <p className="ph-head">Ascender School of Management</p>
              <p className="ph-p">
                Located in Vancouver, Canada, this great institution...{" "}
                <i className="ph-read">Read More</i>
              </p>
            </div>
          </div>

          {/* comp 1 */}
          <div className="partner-comp">
            <img src={usa} alt=""></img>
            {/* hidden area */}
            <div className="partner-hide">
              <p className="ph-head">Baylor University</p>
              <p className="ph-p">
                Baylor's commitment to excellence and a broad range of ...{" "}
                <i className="ph-read">Read More</i>
              </p>
            </div>
          </div>

          {/* comp 1 */}
          <div className="partner-comp">
            <img src={aus} alt=""></img>
            {/* hidden area */}
            <div className="partner-hide">
              <p className="ph-head">Australian National University</p>
              <p className="ph-p">
                The Australian National University (ANU) is a public research
                un... <i className="ph-read">Read More</i>
              </p>
            </div>
          </div>

          {/* comp 1 */}
          <div className="partner-comp">
            <img src={canada2} alt=""></img>
            {/* hidden area */}
            <div className="partner-hide">
              <p className="ph-head">Albert College</p>
              <p className="ph-p">
                Founded in 1857, Albert College is Canada's oldest,
                independent... <i className="ph-read">Read More</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
