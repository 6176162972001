import React from "react";
import FancyButtonComp from "./FancyButtonComp";
import "../Styles/Components/YourGoalsAchievable.css";
import image from "../images/why-foreign.png";

function WhyWorkWithForeign() {
  return (
    <div className="why-foreign">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="wf-left">
          <img src={image} alt=""></img>
        </div>

        {/* right */}
        <div className="wf-right">
          {/* head */}
          <p className="yga-head">Why Work with Foreign Classroom?</p>
          {/* body */}
          <p className="yga-body">
            The growth of your business is certain with us as we save you the
            stress of chasing new agreements, submitting documents and many
            others. We handle the stress for you.
          </p>
          {/* button */}
          <FancyButtonComp name={"Work With Us"} />
        </div>
      </div>
    </div>
  );
}

export default WhyWorkWithForeign;
