import React from "react";
import star from "../images/Star1.svg";
import image from "../images/what-are-clients.png";
import imageOne from "../images/what-are-clients1.png";
import "../Styles/Components/WhatAreClientsSaying.css";

function WhatAreClientsSaying() {
  return (
    <div className="what-are-clients">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="wac-left">
          <img src={image} alt={""}></img>
        </div>

        {/* right */}
        <div className="wac-right">
          {/* up */}
          <div className="wac-r-up">
            <p className="wac-r-up-head">What are clients saying</p>
            <p className="wac-r-up-body">
              Foreign Classroom is a very reliable platform. I’m truly satisfied
              with their services. It deserves a hundred stars
            </p>
          </div>

          {/* down */}
          <div className="wac-r-down">
            {/* up */}
            <div className="wac-rd-up">
              <p>
                They treated me like family. If I could, I would give them 100
                stars
              </p>
            </div>
            {/* down */}
            <div className="wac-rd-down">
              {/* left */}
              <div className="wacrd-d-left">
                {/* l */}
                <div className="wacrdd-l-left">
                  <img src={imageOne} alt={""}></img>
                </div>
                {/* r */}
                <div className="wacrdd-l-right">
                  <p className="wacrdd-lr-bold">James Brown</p>
                  <p className="wacrdd-lr-p">First time Client</p>
                </div>
              </div>
              {/* right */}
              <div className="wacrd-d-right">
                {/* star container */}
                <div className="star-cont">
                  {/* one star */}
                  <div className="one-star">
                    <img src={star} alt={""}></img>
                  </div>
                  {/* one star */}
                  <div className="one-star">
                    <img src={star} alt={""}></img>
                  </div>
                  {/* one star */}
                  <div className="one-star">
                    <img src={star} alt={""}></img>
                  </div>
                  {/* one star */}
                  <div className="one-star">
                    <img src={star} alt={""}></img>
                  </div>
                  {/* one star */}
                  <div className="one-star">
                    <img src={star} alt={""}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatAreClientsSaying;
