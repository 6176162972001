import React from "react";
import "../Styles/Components/Header.css";
// Images
import logo from "../images/logo-jpg.jpg";

function Logo() {
  return (
    <div className="logo-comp">
      <img src={logo} alt="" />
    </div>
  );
}

export default Logo;
