import React from "react";
import "../Styles/Components/JoinGlobalNetwork.css";
import image from "../images/join-network1.svg";
import dotImg from "../images/join-svg.svg";

function JoinGlobalNetwork() {
  return (
    <div className="join-network">
      {/* main background */}
      <div className="join-main">
        {/* image top right */}
        <div className="join-img1">
          <img src={dotImg} alt=""></img>
        </div>
        {/* margin p */}
        <div className="margin-p">
          {/* left */}
          <div className="join-left">
            <p className="join-head">Join The Global Network </p>
            <p className="join-body">
              that connects diverse students with internatonal study
              opportunities
            </p>
            <button className="join-button">Partner With Us</button>
          </div>

          {/* right */}
          <div className="join-right">
            <img src={image} alt=""></img>
          </div>
        </div>

        {/* image bottom left */}
        <div className="join-img2">
          <img src={dotImg} alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default JoinGlobalNetwork;
