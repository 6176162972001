import React from "react";
import FancyButtonComp from "./FancyButtonComp";
import image from "../images/students-big.png";
import curly from "../images/fancy-arrow.svg";
import "../Styles/Components/StudentsSec1.css";

function StudentsSec1() {
  return (
    <div className="students-sec">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="student-left">
          <img src={image} alt=""></img>
        </div>

        {/* right */}
        <div className="student-right">
          {/* curly image */}
          <div className="curly-image">
            <img src={curly} alt=""></img>
          </div>

          {/* main */}
          <div className="student-main">
            {/* head */}
            <p className="student-head">
              Step Up Your Career With Foreign Classroom
            </p>
            {/* body */}
            <p className=" student-body">
              Foreign classroom is dedicated to giving your career the desired
              edge by getting you started on your study abroad journey.
            </p>

            {/* button */}
            <FancyButtonComp name={"Find a Program"} />
          </div>

          {/* curly image */}
          <div className="curly-image2">
            <img src={curly} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsSec1;
