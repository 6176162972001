import React from "react";
import "../Styles/Pages/SignUp.css";
import img1 from "../images/signup-img1.png";
import img2 from "../images/signup-img2.png";
import img3 from "../images/signup-img3.png";
import SignUpOptions from "../Components/SignUpOptions";
import { Link } from "react-router-dom";

function SignUp() {
  return (
    <div className="signup">
      {/* sign up margin */}
      <div className="margin-p2">
        {/* head */}
        <div className="signup-head">
          {/* header */}
          <p className="signup-header">Foreign Classroom Registration</p>
          {/* body */}
          <p className="signup-body">
            Ready to get started with Foreign Classroom? Register today to find
            out how we can support you
          </p>
        </div>

        {/* sign up options */}
        <div className="signup-options">
          {/* sign in comp */}
          <SignUpOptions
            image={img1}
            header={"Student"}
            body={
              "Enjoy easy program search and admission process to the school that best fits your profile and interest."
            }
            button={"Register as a Student"}
          />

          <SignUpOptions
            image={img2}
            header={"Agent"}
            body={
              "Offer your students the opportunity to study at leading educational institutions across the globe."
            }
            button={"Register as a Agent"}
          />

          <SignUpOptions
            image={img3}
            header={"Influencers"}
            body={
              "Do you have followership on any social media platform? Engage your audience and get paid."
            }
            button={"Register as a Influencer"}
          />
        </div>
        {/* already a user prompt */}
        <div className="signup-already">
          <p className="sa-p">Already a Registered User?</p>
          <Link to={"/login"} className="sa-btnn">
            <button className="sa-button">Login</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
