import React from "react";

function StepComponent({ image, number, word }) {
  return (
    <div className="student-sec2-main">
      {/* image */}
      <div className="s-sec2-img">
        <img src={image} alt=""></img>
      </div>

      {/* step */}
      <p className="s-sec2-step">Step {number}</p>

      {/* word */}
      <p className="s-sec2-word">{word}</p>

      {/* blue bottom back */}
      {/* <div className="s-sec2-bottom">
        <p className="ssb-word">Find a Program</p>
      </div> */}
    </div>
  );
}

export default StepComponent;
