import React from "react";
import FancyButtonComp from "./FancyButtonComp";
import "../Styles/Components/StudentSec3.css";
import img from "../images/student-sec3-img.png";
import doted from "../images/pink-doted.svg";

function StudentSec3() {
  return (
    <div className="student-sec3">
      {/* curved back */}
      <div className="ss3-curved-back1"></div>

      {/* main */}
      <div className="ss3-main">
        {/* doted image */}
        <div className="ss3-doted">
          <img src={doted} alt=""></img>
        </div>
        {/* margin p */}
        <div className="margin-p">
          {/* left */}
          <div className="ss3-left">
            <img src={img} alt=""></img>
          </div>
          {/* right */}
          <div className="ss3-right">
            {/* body */}
            <div className="ss3r-body">
              Study in top ranking schools around the world and stay on track
              with your applications here
            </div>
            {/* button */}
            <FancyButtonComp name={"Get Started"} />
          </div>
        </div>

        {/* doted image */}
        <div className="ss3-doted1">
          <img src={doted} alt=""></img>
        </div>
      </div>

      {/* curved back */}
      <div className="ss3-curved-back2"></div>
    </div>
  );
}

export default StudentSec3;
