import React from "react";
import FancyButtonComp from "./FancyButtonComp";
import "../Styles/Pages/AboutUs.css";
import img from "../images/about-us-one.png";
import arrow from "../images/fancy-arrow.svg";

function AboutSec1() {
  return (
    <div className="About-sec-one">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="ab1-left">
          <img src={img} alt=""></img>
        </div>

        {/* right */}
        <div className="ab1-right">
          {/* head */}
          <div className="ab1r-head">
            <p className="ab1rh-red">A Click away from your</p>
            <p className="ab1rh-blue">Dream University!</p>
          </div>

          {/* body */}
          <p className="ab1r-body">
            Foreign classroom is a web based platform created with the motive to
            transform the global educational service sector, through innovative
            technology.{" "}
          </p>

          {/* fancy button */}
          <FancyButtonComp name={"Get in Touch"} />

          {/* fancy arrow */}
          <div className="ab1r-fancy">
            <img src={arrow} alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSec1;
