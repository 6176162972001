import React from "react";
import "../Styles/Components/HomeSec1.css";
import clicker from "../images/onclick.svg";
import { NavLink, useNavigate } from "react-router-dom";

function FancyButtonComp({ name }) {
  return (
    <div className="hs1-button">
      <NavLink
        to="https://app.foreignclassroom.com/create-application"
        className={"hs1-navlink"}
      >
        <div className="hs1-btn-grp">
          <p>{name}</p>
          <div className="hs1-btn-img">
            <img src={clicker} alt=""></img>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default FancyButtonComp;
