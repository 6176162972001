import React from "react";
import MakeYourPresence from "../Components/MakeYourPresence";
import PageNavCheck from "../Components/PageNavCheck";
import Partners from "../Components/Partners";
import PartnerWithForeign from "../Components/PartnerWithForeign";
import WhyPartnerWithForeign from "../Components/WhyPartnerWithForeign";
import WhyWorkWithForeign from "../Components/WhyWorkWithForeign";
import "../Styles/Pages/PartneredSchools.css";
import back from "../images/partnered-back1.svg";
import SupportedBy from "../Components/SupportedBy";
import GetMoreUpdates from "../Components/GetMoreUpdates";
import HomePart3 from "../Components/HomePart3";
import "../Styles/Components/StudentsSec1.css";

function PartneredSchools() {
  return (
    <div className="partner-schools">
      <PageNavCheck title={"Partnered Schools"} image={back} />
      {/* sec1 */}
      <MakeYourPresence />
      <SupportedBy />
      {/* sec2 */}
      <Partners />

      {/* sec3 */}
      <PartnerWithForeign />
      <div className="student-gu">
        <GetMoreUpdates />
      </div>
      {/* <HomePart3 /> */}
    </div>
  );
}

export default PartneredSchools;
