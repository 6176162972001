import React from "react";

function SignUpOptions({ image, header, body, button }) {
  return (
    <div className="signup-comp">
      {/* image */}
      <div className="sc-img">
        <img src={image} alt=""></img>
      </div>

      {/* header */}
      <p className="sc-header">{header}</p>

      {/* body */}
      <p className="sc-body">{body}</p>

      {/* button */}
      <button className="sc-button">{button}</button>
    </div>
  );
}

export default SignUpOptions;
