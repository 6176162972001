import React from "react";
import "../Styles/Pages/Login.css";
import google from "../images/google.svg";
import facebook from "../images/facebookk.svg";
import apple from "../images/apple.svg";
import { Link } from "react-router-dom";

function LoginOther() {
  return (
    <div className="login-other">
      {/* google */}
      <div className="login-with">
        {/* left */}
        <div className="l-with-img">
          <img src={google} alt=""></img>
        </div>

        {/* right */}
        <p className="l-with-p">Login in with Google</p>
      </div>

      {/* facebook */}
      <div className="login-with">
        {/* left */}
        <div className="l-with-img">
          <img src={facebook} alt=""></img>
        </div>

        {/* right */}
        <p className="l-with-p">Login in with Facebook</p>
      </div>

      {/* apple */}
      <div className="login-with">
        {/* left */}
        <div className="l-with-img">
          <img src={apple} alt=""></img>
        </div>

        {/* right */}
        <p className="l-with-p">Login in with Apple</p>
      </div>

      {/* forgot pass */}
      <div className="forgot">
        <p>Forgot Password?</p>
      </div>

      {/* dont have an account */}
      <div className="no-account">
        <p className="dont-have">Dont have an account?</p>
        <Link to={"/signup"}>
          <p className="register">Register</p>
        </Link>
      </div>

      {/* privacy and cookies */}
      <div className="privacy-cookies">
        <p className="privacy">Privacy and Cookies Policies</p>
        <p className="terms">Terms and Conditions</p>
      </div>
    </div>
  );
}

export default LoginOther;
