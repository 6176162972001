import React from "react";
import "../Styles/Components/AccessToWideSec.css";
import image1 from "../images/access1.svg";
import image2 from "../images/access2.svg";
import image3 from "../images/access3.svg";
import image4 from "../images/access4.svg";

import AccessComp from "./AccessComp";

function AccessToWideSec() {
  return (
    <div className="access-to-wide">
      <div className="margin-p2">
        <div className="access-scroll">
          {/* agents frame container */}
          <div className="agents-frame-container">
            {/* 1st frame */}
            <div className="access-flex">
              {/* access comp */}
              <AccessComp
                image={image1}
                header={"Access to a wide range of schools"}
                word={
                  "Our partnership with institutions around the world puts your students up for limitless applications"
                }
              />

              {/* access comp */}
              <AccessComp
                image={image2}
                header={"Track all applications conveniently"}
                word={
                  "Our partnership with institutions around the world puts your students up for limitless applications"
                }
              />

              {/* access comp */}
              <AccessComp
                image={image3}
                header={"Business Expansion"}
                word={
                  "We will refer students in your location to your agency, so you can help with their application and get the commission."
                }
              />

              {/* access comp */}
              <AccessComp
                image={image4}
                header={"Trainings and Support"}
                word={"Sign up as an agent"}
              />
            </div>

            {/* 2nd frame */}
            <div className="access-flex">
              {/* access comp */}
              <AccessComp
                image={image1}
                header={"Access to a wide range of schools"}
                word={
                  "Our partnership with institutions around the world puts your students up for limitless applications"
                }
              />

              {/* access comp */}
              <AccessComp
                image={image2}
                header={"Track all applications conveniently"}
                word={
                  "Our partnership with institutions around the world puts your students up for limitless applications"
                }
              />

              {/* access comp */}
              <AccessComp
                image={image3}
                header={"Business Expansion"}
                word={
                  "We will refer students in your location to your agency, so you can help with their application and get the commission."
                }
              />

              {/* access comp */}
              <AccessComp
                image={image4}
                header={"Trainings and Support"}
                word={"Sign up as an agent"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessToWideSec;
