import React, { useState } from "react";
import arrDown from "../images/angle-down.svg";

function DownComp({ head, body, image }) {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="whypl-comp"
      onClick={() => {
        setHover(!hover);
      }}
    >
      {/* visible */}
      <div className="whyplc-visible">
        <div className="wv-left">
          <div className="wvl-img">
            <img src={image} alt=""></img>
          </div>
          <p className="wvl-p">{head}</p>
        </div>

        <div className={hover ? "wv-right1" : "wv-right"}>
          <img src={arrDown} alt=""></img>
        </div>
      </div>
      {/* hidden */}
      <div className={hover ? "whyplc-hidden" : "whyplc-hidden-none"}>
        <p>{body}</p>
      </div>
    </div>
  );
}

export default DownComp;
