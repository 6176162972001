import React from "react";
import FancyButtonComp from "./FancyButtonComp";

function HeroComp({ image, word, button }) {
  const backStyles = {
    backgroundImage: `url(${image})`,
  };
  return (
    <div className="hero-comp">
      <div className="hero-phone">
        <div className="hero-phone-main">
          <div className="hero-comp-main">
            <img src={image} alt=""></img>
          </div>
        </div>

        {/* margin p2 */}

        <div className="hero-inside">
          {/* head */}
          <p className="hero-p">{word}</p>
          <FancyButtonComp name={button} />
        </div>
      </div>
    </div>
  );
}

export default HeroComp;
