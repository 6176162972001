import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import MainContainer from "./Components/MainContainer";
import PhoneHeader from "./Components/PhoneHeader";
import Faq from "./FAQ/Faq";

import Login from "./Pages/Login";

import SignUp from "./Pages/SignUp";

function App() {
  return (
    <div className="App">
      {/* <Faq /> */}

      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/*" element={<MainContainer />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
