import React from "react";
import FancyButtonComp from "./FancyButtonComp";
import markOne from "../images/mark-p1.png";
import markTwo from "../images/mark-p2.png";
import markThree from "../images/mark-p3.png";
import markFour from "../images/mark-p4.png";
import markFive from "../images/mark-p5.png";
import fancy from "../images/fancy-arrow.svg";

function MakeYourPresence() {
  return (
    <div className="mark-presence">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="mp-left">
          {/* curl */}
          <div className="mp-curl-image">
            <img src={fancy} alt=""></img>
          </div>

          {/* header */}
          <p className="mp-header">
            Mark your presence in the International Market!
          </p>

          {/* body */}
          <p className="mp-body">
            Foreign classroom focuses on actively recruiting qualified students
            from various parts of the world to your institution
          </p>

          {/* button */}
          <FancyButtonComp name={"Partner With Us"} />

          {/* curl */}
          <div className="mp-curl-image1">
            <img src={fancy} alt=""></img>
          </div>
        </div>

        {/* right */}
        <div className="mp-right">
          {/* left */}
          <div className="mp-r-left">
            <img src={markOne} alt=""></img>
          </div>

          {/* right */}
          <div className="mp-r-right">
            {/* up */}
            <div className="mp-rr-up">
              <img src={markTwo} alt=""></img>
            </div>

            {/* down */}
            <div className="mp-rr-down">
              {/* comp */}
              <div className="mprr-d-img">
                <img src={markThree} alt=""></img>
              </div>

              {/* comp */}
              <div className="mprr-d-img">
                <img src={markFour} alt=""></img>
              </div>

              {/* comp */}
              <div className="mprr-d-img">
                <img src={markFive} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MakeYourPresence;
