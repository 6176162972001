import React from "react";
import albert from "../images/albert-college.svg";
import ba from "../images/ba-college.svg";
import bu from "../images/bu-college.svg";
import anu from "../images/anu-college.svg";
import ascenda from "../images/ascenda-college.svg";
import alex from "../images/alex-college.svg";
import one from "../images/school-image-one1.svg";
import two from "../images/school-image-two1.svg";
import three from "../images/school-image-three1.svg";
import four from "../images/school-image-four1.svg";
import five from "../images/school-image-five1.svg";
import six from "../images/school-image-six1.svg";
import seven from "../images/school-image-seven1.svg";
import eight from "../images/school-image-eight1.svg";
import nine from "../images/school-image-nine1.svg";
import ten from "../images/school-image-ten1.svg";

import "../Styles/Components/SupportedBy.css";
import { useRef } from "react";

function SupportedBy() {
  return (
    <div className="support-by">
      {/* head */}
      <p className="support-head">SUPPORTED BY</p>
      <div className="margin-p2">
        {/* images to scroll */}

        <div className="support-scroll">
          {/* container of frames */}
          <div className="frame-container">
            {/* 1st frame */}
            <div className="support-main">
              {/* image comp */}
              <div className="support-comp">
                <img src={one} alt=""></img>
              </div>

              {/* image comp */}
              <div className="support-comp">
                <img src={two} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={three} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={four} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={five} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={six} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={seven} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={eight} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={nine} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={ten} alt=""></img>
              </div>
            </div>
            {/* 2nd frame */}
            <div className="support-main">
              {/* image comp */}
              <div className="support-comp">
                <img src={one} alt=""></img>
              </div>

              {/* image comp */}
              <div className="support-comp">
                <img src={two} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={three} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={four} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={five} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={six} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={seven} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={eight} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={ten} alt=""></img>
              </div>
              {/* image comp */}
              <div className="support-comp">
                <img src={nine} alt=""></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportedBy;
