import React from "react";
import "../Styles/Components/HomePart3.css";
import image from "../images/hp3-img.png";
import blog from "../images/blog-11.svg";
import blogTwo from "../images/blog-22.svg";
import BlogComp from "./BlogComp";

function HomePart3() {
  return (
    <div className="home-part-3">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="hp3-left">
          <img src={image} alt=""></img>
        </div>

        {/* right */}
        <div className="hp3-right">
          {/* head */}
          <div className="hp3r-head">
            {/* word */}
            <p className="hp3r-word">News / Blog</p>

            {/* button */}
            <button className="hp3r-button">All Posts</button>
          </div>

          {/* blog comp */}
          <BlogComp
            image={blog}
            head={"Why Study Design and Illustration?"}
            body={
              "While it is pretty well known that design and illustration  are everywhere... "
            }
            date={"NOV 24, 2022"}
          />

          <BlogComp
            image={blogTwo}
            head={"Study Abroad Success Story : Stacey"}
            body={
              "The story of Stacey is not your regular Study Abroad story, a rare combinat... "
            }
            date={"NOV 22, 2022"}
          />
        </div>
      </div>
    </div>
  );
}

export default HomePart3;
