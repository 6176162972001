import React from "react";
import PageNavCheck from "./PageNavCheck";
import back from "../images/agent-back.png";

function AgentsHero() {
  return (
    <div>
      <PageNavCheck title={"Agents"} image={back} />
    </div>
  );
}

export default AgentsHero;
