import React from "react";
import "../Styles/Components/PageNavCheck.css";

import arrow from "../images/two-arrow.svg";

function PageNavCheck({ title, image }) {
  const style = {
    backgroundImage: `url(${image})`,
  };
  return (
    <div className="page-nav-check" style={style}>
      {/* overlay */}
      <div className="pnc-overlay">
        {/* marginp */}
        <div className="margin-p2">
          <p className="pnc-head">{title}</p>
          <div className="pnc-group">
            <p className="pnc-word">Home</p>
            <div className="pnc-img">
              <img src={arrow} alt=""></img>
            </div>

            <p className="pnc-word">{title}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNavCheck;
