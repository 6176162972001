import React from "react";
import FancyButtonComp from "./FancyButtonComp";
import img1 from "../images/a-seam-big.png";
import img2 from "../images/a-seam-small1.svg";

function ASeamless() {
  return (
    <div className="a-seamless">
      {/* margin */}
      <div className="margin-p">
        {/* left */}
        <div className="a-seam-left">
          {/* up */}
          <div className="a-seam-lu">
            {/* head */}
            <p className="a-seam-lu-head">
              A seamless application process is guaranteed!
            </p>
            {/* body */}
            <p className="a-seam-lu-body">
              Our core activity lies in assisting students to experience a
              seamless application, enrollment and visa process to colleges and
              universities around the world.{" "}
            </p>
          </div>

          {/* down */}
          <div className="a-seam-ld">
            {/* left */}
            <div className="a-seam-ldl">
              <img src={img2} alt=""></img>
            </div>
            {/* right */}
            <div className="a-seam-ldr">
              {/* head */}
              <p className="a-seam-ldrh">
                Adequate guidance and support are provided
              </p>
              {/* body */}
              <p className="a-seam-ldrb">
                With our team of dedicated professionals, adequate guidance and
                support will be provided to ensure every student fulfills their
                career ambitions
              </p>
            </div>
          </div>

          {/* fancy button */}
          <FancyButtonComp name={"Get in Touch"} />
        </div>

        {/* right */}
        <div className="a-seam-right">
          <img src={img1} alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default ASeamless;
