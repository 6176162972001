import React from "react";
import GetMoreUpdates from "../Components/GetMoreUpdates";
import HomePart3 from "../Components/HomePart3";
import InfluencersSec1 from "../Components/InfluencersSec1";
import PageNavCheck from "../Components/PageNavCheck";
import SupportedBy from "../Components/SupportedBy";
import WhyAudience from "../Components/WhyAudience";
import WhyPartnerWithForeign from "../Components/WhyPartnerWithForeign";
import back from "../images/influencer-nav-check.png";
import "../Styles/Components/StudentsSec1.css";

function Influencers() {
  return (
    <div className="influencers">
      <PageNavCheck title={"Influencers"} image={back} />
      {/* sec1 */}

      <InfluencersSec1 />
      <SupportedBy />
      {/* sec 2 */}
      <WhyPartnerWithForeign />

      <div className="student-gu">
        <GetMoreUpdates />
      </div>
      {/* <HomePart3 /> */}

      {/* sec3 */}
      <WhyAudience />
    </div>
  );
}

export default Influencers;
