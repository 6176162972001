import React from "react";
import image from "../images/wed-our-strategy.png";
import imagePng from "../images/our_vision_svg1.svg";
import imageSvg from "../images/our_vision_svg.svg";
import imagePng1 from "../images/our_strategy_svg1.svg";
import imageSvg1 from "../images/our_strategy_svg.svg";
import imagePng2 from "../images/our_mission_svg1.svg";
import imageSvg2 from "../images/our_mission_svg.svg";
import WeAreComp from "./WeAreComp";

function WeAreDedicated() {
  return (
    <div className="we-are-dedicated">
      <div className="margin-p2">
        {/* header */}
        <p className="wed-header">We are dedicated to all round Excellence!</p>

        {/* flex comp */}
        <div className="wad-grid">
          <WeAreComp
            Svg={imageSvg}
            Png={imagePng}
            head={"Our Vision"}
            word={
              "To be the world’s leading platform that uses unique techniques to  bridge between institutions and  international students around the world."
            }
          />

          <WeAreComp
            Svg={imageSvg1}
            Png={imagePng1}
            head={"Our Strategy"}
            word={
              "We stand firm in our commitment to our students, recruitment agents influencers and institutional partners by delivering value  in order to produce long term  partnership and satisfaction."
            }
          />

          <WeAreComp
            Svg={imageSvg2}
            Png={imagePng2}
            head={"Our Mission"}
            word={
              "Our goal is to ensure every student has access to the best possible institutions and academic programs to enable them to reach their full potentials"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default WeAreDedicated;
