import React from "react";
import InfluTwo from "../images/influencersImg2.png";
import support from "../images/support-agent.svg";
import medal from "../images/medal-outline.svg";

import clarity from "../images/clarity-form-line.svg";
import { useState } from "react";
import DownComp from "./DownComp";

function WhyPartnerWithForeign() {
  return (
    <div className="why-partner">
      {/* margin p */}
      <div className="margin-p">
        {/* left */}
        <div className="whyp-left">
          {/* header */}
          <p className="whypl-header">Why Partner With Foreign Classroom</p>

          {/* down comp */}
          <DownComp
            head={"Free Registration"}
            body={
              "Click on the sign-up button to register and get your coupon code instantly."
            }
            image={clarity}
          />
          <DownComp
            head={"Team Support"}
            body={
              "Our team will ensure you get support at every point. We will also provide you with updated materials to educate your audience. "
            }
            image={support}
          />

          <DownComp
            head={"Reward"}
            body={
              "Get rewarded for every student that successfully enrolls through your referral code."
            }
            image={medal}
          />
        </div>

        {/* right */}
        <div className="whyp-right">
          <img src={InfluTwo} alt=""></img>
        </div>
      </div>
    </div>
  );
}

export default WhyPartnerWithForeign;
