import React, { useRef, useState } from "react";

function PartnerComp({ image, head, body, color }) {
  const section = useRef();
  const [change, setChange] = useState(false);

  window.addEventListener("scroll", () => {
    var top = section.current?.getBoundingClientRect().top;

    if (top < window.innerHeight - 180) {
      setChange(true);
    }
  });
  return (
    <div className="pwf-c-comp" ref={section}>
      <div className={change ? " pwf-comp" : "display-slider2"}>
        <div className="pwfc-img">
          <img src={image} alt=""></img>
        </div>

        <p className={`pwfc-head ${color}`}>{head}</p>
        <p className="pwfc-word">{body}</p>
      </div>
    </div>
  );
}

export default PartnerComp;
